(function () {
  const burger = document?.querySelector('[data-burger]');
  const menu = document?.querySelector('[data-menu]');
  const menuItems = document?.querySelectorAll('.nav__link'); // Указываем на ссылки
  const overlay = document?.querySelector('[data-menu-overlay]');

  burger?.addEventListener('click', (e) => {
    burger?.classList.toggle('burger--active');
    menu?.classList.toggle('menu--active');

    if (menu?.classList.contains('menu--active')) {
      burger?.setAttribute('aria-expanded', 'true');
      burger?.setAttribute('aria-label', 'Закрыть меню');
      document.body.classList.add('no-scroll'); // Блокируем прокрутку
    } else {
      burger?.setAttribute('aria-expanded', 'false');
      burger?.setAttribute('aria-label', 'Открыть меню');
      document.body.classList.remove('no-scroll'); // Разрешаем прокрутку
    }
  });

  overlay?.addEventListener('click', () => {
    closeMenu();
  });

  menuItems?.forEach(el => {
    el.addEventListener('click', (e) => {
      const href = el.getAttribute('href');

      // Проверяем, является ли ссылка якорной ссылкой на этой же странице
      if (href.startsWith('#')) {
        e.preventDefault(); // Останавливаем стандартное поведение

        // Прокручиваем до якоря
        const targetElement = document.querySelector(href);
        if (targetElement) {
          closeMenu(); // Закрываем меню перед прокруткой

          // Прокручиваем плавно
          setTimeout(() => {
            targetElement.scrollIntoView({
              behavior: 'smooth',
              block: 'start'
            });
          }, 300); // Задержка, чтобы дождаться закрытия меню
        }
      } else {
        closeMenu(); // Закрываем меню для обычных ссылок
      }
    });
  });

  function closeMenu() {
    burger?.setAttribute('aria-expanded', 'false');
    burger?.setAttribute('aria-label', 'Открыть меню');
    burger.classList.remove('burger--active');
    menu.classList.remove('menu--active');
    document.body.classList.remove('no-scroll'); // Разрешаем прокрутку
  }
})();
